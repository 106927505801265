// @flow
import * as Sentry from "@sentry/react";
import * as React from "react";
import abstractConfig from "abstract-di/config";
import { isStagingDomain } from "core/lib/environment";

export function enableSentry(
  dsn: ?string,
  routes: React.Node,
  integrations?: any[]
): void {
  if (!dsn || process.env.NODE_ENV === "development") {
    return;
  }

  Sentry.init({
    dsn,
    environment: process.env.SENTRY_ENVIRONMENT,
    release: abstractConfig.version,
    integrations,
    tracesSampleRate:
      process.env.NODE_ENV === "production" && !isStagingDomain ? 0.1 : 1.0, // 10% in production
    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "ChunkLoadError",
      "Can't find variable: rdt",
      "The Internet connection appears to be offline.",
    ],
  });
}

export function reportError(error: Error, options: { extras: {} } = {}): void {
  if (process.env.NODE_ENV === "development") {
    throw error;
  }

  Sentry.withScope((scope) => {
    if (options.extras) {
      scope.setExtras(options.extras);
    }

    Sentry.captureException(error);
  });
}

export const client = Sentry;
